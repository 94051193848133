import React from "react";
import HeroSection from "./heroSection";
import HomeImportant from "./homeImportant";
import Help from "./help";

export default function home() {
  return (
    <div>
      <HeroSection />
      <HomeImportant />
      <Help />
    </div>
  );
}

import React from "react";
import FooterSectionOne from "./footerSectionOne";
import FooterSectionTwo from "./footersectionTwo";

export default function footer() {
  return (
    <div className="">
      <div>
        <FooterSectionOne />
      </div>
      <div>
        <FooterSectionTwo />
      </div>
    </div>
  );
}

import React from "react";
import HeaderImage from "../../assets/header-image.jpg";

export default function homeImportant() {
  return (
    <div className="flex items-center justify-center px-40 max-[1024px]:px-10 my-10 text-white text-center text-base">
      <div className="flex items-center justify-center flex-col">
        <div className="flex items-center justify-center flex-col p-5 gap-5 bg-blue-600">
          <div className="flex items-center justify-center text-center font-bold text-2xl">
            <span>A Blog: Where Stories and Insights Resonate</span>
          </div>
          <div className="max-[768px]:text-sm">
            In a world overflowing with fleeting trends and fast information, a
            blog stands as a sanctuary for deeper reflection and meaningful
            connection. Here, each post is crafted with purpose, offering
            readers a chance to pause, ponder, and immerse themselves in stories
            that resonate. A blog isn’t just a collection of words; it’s a
            bridge to new perspectives, a source of inspiration, and a space to
            explore ideas that might otherwise remain unspoken. Whether you’re
            seeking knowledge, comfort, or a fresh outlook, a well-curated blog
            can be a companion on your journey of lifelong learning and growth.
          </div>
        </div>
        <div>
          <img alt="header-image" src={HeaderImage} />
        </div>
      </div>
    </div>
  );
}

import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer/footer";
import Navbar from "./components/Navbar/navbar";
import Home from "./components/Home/home";
import Blog from "./components/Blog/blog";
import FashionBlog from "./components/FashionBlog/FashionBlog";
import TechnologyBlog from "./components/TechnologyBlog/TechnologyBlog";
import BlogDetailPage from "./components/BlogDetailPage/BlogDetailPage";
import PrivacyPolicy from "./components/AboutUs/AboutUs";

function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:id" element={<BlogDetailPage />} />
            <Route path="/blog/fashion" element={<FashionBlog />} />
            <Route path="/blog/technology" element={<TechnologyBlog />} />
            <Route path="/about-us" element={<PrivacyPolicy />} />
          </Routes>
        </main>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;

import React from "react";
import logo from "../../assets/main-logo.png";
import { Link } from "react-router-dom";

export default function navLogo() {
  return (
    <Link to={"/"} className="flex items-center justify-center  py-5">
      <img src={logo} alt="epic-logo" className="w-60 " />
    </Link>
  );
}

import React, { useState } from "react";
import NavbarLogo from "./navLogo";
import NavbarLinks from "./navLinks";
import { RxCross2, RxHamburgerMenu } from "react-icons/rx";
import { Link } from "react-router-dom";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

export default function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLinkClick = () => {
    setIsMenuOpen(false);
    setIsDropdownOpen(false);
  };

  return (
    <div className="flex items-center justify-between px-10 border-b">
      <NavbarLogo />
      <div
        onClick={toggleMenu}
        className="cursor-pointer hidden max-[1024px]:flex"
      >
        {isMenuOpen ? (
          <RxCross2 size={22} className="text-blue-700" />
        ) : (
          <RxHamburgerMenu size={22} className="text-blue-700" />
        )}
      </div>
      <div className="flex items-center justify-center gap-5 max-[1024px]:hidden">
        <NavbarLinks />
      </div>
      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="absolute top-20 flex-col left-0 w-full bg-white shadow-lg shadow-blue-100 py-5 border-t hidden max-[1024px]:flex">
          <ul className="flex flex-col items-center w-full">
            <li className="w-full">
              <Link
                to="/"
                className="block text-xl px-5 py-3 hover:bg-gray-100 hover:text-blue-700"
                onClick={handleLinkClick}
              >
                Home
              </Link>
            </li>
            <li className="w-full">
              <div className="flex items-center justify-between text-xl px-5 py-3 hover:bg-gray-100 hover:text-blue-700">
                <Link
                  to="/blog"
                  className="block w-full"
                  onClick={handleLinkClick}
                >
                  Blog
                </Link>
                <button className="ml-2 text-blue-700" onClick={toggleDropdown}>
                  {isDropdownOpen ? (
                    <IoIosArrowUp size={22} />
                  ) : (
                    <IoIosArrowDown size={22} />
                  )}
                </button>
              </div>
              {isDropdownOpen && (
                <ul className="flex flex-col w-full">
                  <li>
                    <Link
                      to="/blog/fashion"
                      className="block text-xl px-10 py-3 hover:bg-gray-100 hover:text-blue-700"
                      onClick={handleLinkClick}
                    >
                      Fashion
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/blog/technology"
                      className="block text-xl px-10 py-3 hover:bg-gray-100 hover:text-blue-700"
                      onClick={handleLinkClick}
                    >
                      Technology
                    </Link>
                  </li>
                </ul>
              )}
            </li>
            <li className="w-full">
              <Link
                to="/about-us"
                className="block text-xl px-5 py-3 hover:bg-gray-100 hover:text-blue-700"
                onClick={handleLinkClick}
              >
                About Us
              </Link>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

import React from "react";

export default function footersectionTwo() {
  return (
    <div className="flex items-center justify-center text-lg text-white text-center bg-slate-900 p-16">
      <div>
        <span className="text-base">Copyright © 2024 Epic Globe Services</span>
      </div>
    </div>
  );
}

import React from "react";
import FooterSectionOneDisclaimer from "./footerSectionOneDisclaimer";
import FooterSectionOneLinks from "./footerSectionOneLinks";

export default function footerSectionOne() {
  return (
    <div className="flex items-center justify-center bg-blue-600 w-full p-16  max-[1024px]:flex-col  max-[1024px]:gap-10">
      <div className="flex-1">
        <FooterSectionOneLinks />
      </div>
      <div className="flex-1">
        <FooterSectionOneDisclaimer />
      </div>
    </div>
  );
}

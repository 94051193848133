import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { blogData } from "../Blog/blogData";

export default function BlogDetailPage() {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    if (id) {
      const selectedBlog = blogData.find(
        (blog) => blog.id === parseInt(id, 10)
      );
      setBlog(selectedBlog || null);
    }
  }, [id]);

  if (!blog) {
    return <div>Loading...</div>;
  }

  return (
    <div className="px-10 md:px-28 py-10">
      <div className="w-full mb-4 flex items-center justify-center">
        <div
          id="image-container"
          style={{
            backgroundImage: `url(/assets/${blog.image})`,
          }}
          className="bg-cover w-full bg-center bg-no-repeat md:h-[420px] xl:h-[720px] h-[220px] rounded"
        ></div>
      </div>

      <h1 className="text-3xl font-bold mb-4">{blog.title}</h1>

      <div className="flex items-center gap-1 text-base text-black mb-2">
        <span className="font-semibold">Published by:</span>
        <span>{blog.publisher}</span>
      </div>

      <div className="mb-4 text-black text-base">
        <p>{blog.content}</p>
      </div>
    </div>
  );
}

import { Link } from "react-router-dom";
import React from "react";

export default function footerSectionOneDisclaimer() {
  return (
    <div className="flex items-center justify-center flex-col gap-5 text-lg text-white text-center">
      <div>
        <span className="text-4xl font-bold">Disclaimer</span>
      </div>
      <div>
        <p className="text-sm">
          The content on our site is free to use. Any financial results are not
          implied or guaranteed. Any results depend solely on the action taken
          by the individual. We monetize our service with advertisment, but no
          content will prioritized based on other things than editorial
          relevance. For further questions we refer to our{" "}
          <Link to={"/about-us"} className="underline font-bold">privacy policy</Link>.
        </p>
      </div>
    </div>
  );
}

import { Link } from "react-router-dom";
import React from "react";
import FooterLogo from "../../assets/footer-logo.png";

export default function footerSectionOneLinks() {
  return (
    <div className="flex items-center justify-center flex-col gap-5 text-lg text-white text-center ">
      <div>
        <img src={FooterLogo} alt="footer-logo" className="w-16" />
      </div>
      <div>
        <span className="font-bold">More About Our Services</span>
      </div>
      <div>
        <ul className="flex items-center justify-center gap-5 flex-wrap">
          <li>
            <Link
              to={"/"}
              className="transition ease-in-out delay-75 duration-150 hover:underline"
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to={"/about-us"}
              className="transition ease-in-out delay-75 duration-150 hover:underline"
            >
              About Us
            </Link>
          </li>
          <li>
            <Link
              to={"/blog"}
              className="transition ease-in-out delay-75 duration-150 hover:underline"
            >
              Blog
            </Link>
          </li>
          <li>
            <Link
              to={"/about-us"}
              className="transition ease-in-out delay-75 duration-150 hover:underline"
            >
              Privacy Policy
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

import React from "react";

export default function PrivacyPolicy() {
  return (
    <div className=" w-full min-h-screen  text-gray-900">
      <header className=" py-6 text-center">
        <h1 className="text-3xl font-semibold">Privacy Policy</h1>
      </header>

      <main className="px-6 py-12 max-w-4xl mx-auto">
        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-4">Introduction</h2>
          <p>
            Welcome to Epic Globe Services. Your privacy is of paramount
            importance to us. This Privacy Policy explains how we collect, use,
            and protect your personal information.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-4">Information Collection</h2>
          <p>
            We collect information that you provide to us directly, such as when
            you sign up, make a purchase, or contact us. We may also collect
            information automatically, like IP address of your device, browser
            type, and usage patterns.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-4">
            How We Use Your Information
          </h2>
          <p>
            We use your personal information to improve our services, process
            transactions, communicate with you, and comply with legal
            requirements. We may also use it for marketing purposes, with your
            consent.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-4">
            Cookies and Tracking Technologies
          </h2>
          <p>
            We use cookies to enhance user experience, analyze website traffic,
            and personalize content. You can manage your cookie preferences
            through your browser settings.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-4">Data Sharing</h2>
          <p>
            We do not sell or rent your personal data. However, we may share it
            with trusted third parties, such as service providers and partners,
            to help run our website and improve our services.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-4">Your Rights</h2>
          <p>
            You have the right to access, update, or delete your personal
            information. If you wish to exercise any of these rights, please
            contact us at [Your Email Address].
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-4">Security</h2>
          <p>
            We take reasonable measures to protect your personal information
            from unauthorized access or disclosure. However, no method of
            transmission over the internet is 100% secure, so we cannot
            guarantee absolute security.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-4">Third-Party Links</h2>
          <p>
            Our website may contain links to external sites. We are not
            responsible for the privacy practices of these sites, and we
            encourage you to read their privacy policies.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-4">
            Changes to This Privacy Policy
          </h2>
          <p>
            We may update this Privacy Policy from time to time. Any changes
            will be posted on this page, and we encourage you to review it
            periodically.
          </p>
        </section>
      </main>
    </div>
  );
}

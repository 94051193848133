import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { TechnologyBlogData } from "./TechnologyBlogData";

export default function TechnologyBlog() {
  const [blogs, setBlogs] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const location = useLocation();
  const blogsPerPage = 5;

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const page = parseInt(query.get("page") || "1", 10);
    setCurrentPage(page);

    const getBlogs = () => {
      const allBlogs = [...TechnologyBlogData].reverse();
      setTotalPages(Math.ceil(allBlogs.length / blogsPerPage));
      const paginatedBlogs = allBlogs.slice(
        (page - 1) * blogsPerPage,
        page * blogsPerPage
      );
      setBlogs(paginatedBlogs);
    };

    getBlogs();
  }, [location.search]);

  return (
    <>
      <div className=" w-full flex items-center justify-center flex-col p-5 gap-5">
        {blogs.map((blog) => (
          <div
            key={blog.id}
            className=" w-full h-[1000xp] flex items-center justify-center flex-col border border-gray-300 rounded-lg p-5 shadow-md bg-white"
          >
            <div className=" w-full mb-4 flex items-center justify-center">
              <div
                id="image-container"
                style={{
                  backgroundImage: `url(/assets/${blog.image})`,
                }}
                className="bg-cover w-full bg-center bg-no-repeat md:h-[420px] xl:h-[720px] h-[220px] rounded"
              ></div>
            </div>

            <h2 className="text-2xl font-bold mb-2">{blog.title}</h2>

            <div className="flex items-center gap-1 text-sm text-black mb-2">
              <span className="font-semibold text-base text-black">
                Published by:
              </span>
              <span className="text-base text-black">{blog.publisher}</span>
            </div>

            <p className="text-black mb-4 text-base">
              <span className="font-semibold text-base text-black">
                Main Idea:
              </span>{" "}
              {blog.mainIdea}
            </p>

            <div className="flex justify-end">
              <Link
                to={`/blog/${blog.id}`}
                className="flex items-center text-blue-600 font-semibold hover:text-blue-800 text-base"
              >
                <span>Read More</span>
                <MdKeyboardDoubleArrowRight className="ml-1" />
              </Link>
            </div>
          </div>
        ))}
      </div>

      <div className="flex justify-around items-center mt-6 p-5 gap-2">
        <Link
          to={`?page=${currentPage - 1}`}
          className={`px-4 py-2 rounded ${
            currentPage === 1
              ? "bg-gray-300 text-gray-500 cursor-not-allowed pointer-events-none"
              : "bg-blue-500 text-white hover:bg-blue-600"
          }`}
        >
          Previous
        </Link>
        <span className="text-lg font-medium">
          Page {currentPage} of {totalPages}
        </span>
        <Link
          to={`?page=${currentPage + 1}`}
          className={`px-4 py-2 rounded ${
            currentPage === totalPages
              ? "bg-gray-300 text-gray-500 cursor-not-allowed pointer-events-none"
              : "bg-blue-500 text-white hover:bg-blue-600"
          }`}
        >
          Next
        </Link>
      </div>
    </>
  );
}

import React from "react";

export default function HeroSection() {
  return (
    <div className="flex items-center justify-center text-white">
      <div
        id="hero-image"
        className="w-full h-[550px] max-[900px]:h-[450px] max-[768px]:h-[350px] max-[640px]:h-[300px] max-[480px]:h-[250px]"
      >
        <div className="w-full h-full bg-black bg-opacity-50 flex items-end justify-center flex-col text-center gap-10 ">
          <div className="flex items-center justify-center flex-col gap-6 w-[60%] max-[768px]:w-[80%] max-[640px]:w-[90%] max-[480px]:w-full">
            <div className="flex items-center justify-center">
              <span className="flex items-center justify-center font-bold text-4xl w-[100%] max-[1024px]:ml-10 max-[900px]:text-3xl max-[768px]:text-3xl max-[640px]:text-2xl max-[480px]:text-xl max-[768px]:ml-20  mr-20  ">
                Welcome to a World of Insights and Stories
              </span>
            </div>
            <div className="flex items-center justify-center text-base w-[500px] italic max-[1024px]:ml-10 max-[900px]:text-sm max-[900px]:w-[450px] max-[768px]:w-[380px] max-[640px]:w-[90%] max-[480px]:text-xs max-[768px]:ml-20 mr-20 : ">
              Journey through carefully crafted stories and perspectives, where
              each post aims to inspire, inform, and ignite curiosity. Dive in,
              stay a while, and discover content that resonates deeply. This
              space is dedicated to sharing ideas and reflections meant just for
              you.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";

export default function help() {
  return (
    <div className="flex items-center justify-center flex-col gap-10 mb-10 px-5">
      <div className="text-blue-600 font-bold text-4xl text-center">
        <span className="text-center">How Do We Help?</span>
      </div>
      <div className="text-center leading-10 text-base">
        <b>We</b> believe in creating a space where ideas can inspire and
        connect.
        <br />
        <b>Explore</b> insights and perspectives that encourage growth and
        reflection.
        <br />
        <b>With</b> each post, we strive to bring you stories that go beyond the
        ordinary.
        <br />
        <b>Our</b> blog is more than information; it’s a source of wisdom and
        meaningful guidance.
        <br />
        <b>Friends</b> of our blog know they’ll find content that resonates,
        uplifts, and enriches their daily lives.
      </div>
    </div>
  );
}

import { Link } from "react-router-dom";
import React, { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";

export default function NavLinks() {
  const [isBlogDropdownOpen, setIsBlogDropdownOpen] = useState(false);

  return (
    <div className="flex items-center justify-center text-slate-950 text-lg font-bold h-full">
      <ul className="flex items-center justify-center gap-7 h-full">
        {/* Home Link */}
        <li className="flex items-center justify-center">
          <Link
            to="/"
            className="flex items-center justify-center gap-2 transition delay-75 duration-100 ease-in-out hover:text-blue-700 hover:underline py-5"
          >
            <span>Home</span>
          </Link>
        </li>

        {/* Blog Link with Dropdown */}
        <li
          className="relative flex items-center justify-center"
          onMouseEnter={() => setIsBlogDropdownOpen(true)}
          onMouseLeave={() => setIsBlogDropdownOpen(false)}
        >
          <Link
            to="/blog"
            className="flex items-center justify-center gap-1 transition delay-150 duration-150 ease-in-out hover:text-blue-700 hover:underline  py-5"
          >
            <span>Blog</span>
            <IoIosArrowDown />
          </Link>
          {isBlogDropdownOpen && (
            <ul className="absolute top-16 left-0 mt-2 w-40 bg-white border border-gray-300 shadow-lg font-semibold ">
              <li className="px-4 py-2 transition delay-150 duration-150 ease-in-out hover:text-blue-600 hover:bg-slate-100">
                <Link to="/blog/fashion">Fashion</Link>
              </li>
              <li className="px-4 py-2 transition delay-150 duration-150 ease-in-out  hover:text-blue-600 hover:bg-slate-100">
                <Link to="/blog/technology">Technology</Link>
              </li>
            </ul>
          )}
        </li>

        {/* About Us Link */}
        <li className="flex items-center justify-center">
          <Link
            to="/about-us"
            className="flex items-center justify-center gap-1  transition delay-75 duration-100 ease-in-out hover:text-blue-700 hover:underline  py-6"
          >
            <span>About Us</span>
          </Link>
        </li>
      </ul>
    </div>
  );
}
